<template>
  <div>
    <vue-element-loading
      :active="appLoading"
      :is-full-screen="true"
      background-color="#FFFFFF"
      color="#182444"
      spinner="bar-fade-scale"
    />
    <ServerError v-if="ServerError" />
    <v-snackbar v-model="showSnackBar" color="#ff6200" right top timeout="2000">
      <v-layout wrap justify-center>
        <v-flex text-left class="align-self-center">
          <span style="color: white">
            {{ msg }}
          </span>
        </v-flex>

        <v-flex text-right>
          <v-btn small :ripple="false" text @click="showSnackBar = false">
            <v-icon style="color: white">mdi-close</v-icon>
          </v-btn>
        </v-flex>
      </v-layout>
    </v-snackbar>
    <v-layout wrap justify-center>
      <v-flex xs12>
        <!-- <v-layout wrap justify-start pt-2>
          <v-flex xs12 sm3 align-self-center>
            <span
              class="title1"
              :style="{
                'font-size':
                  $vuetify.breakpoint.name == 'xs'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                    ? '25px'
                    : $vuetify.breakpoint.name == 'lg'
                    ? '25px'
                    : '30px',
              }"
              >Property Details</span
            >
          </v-flex>
        </v-layout> -->
        <v-layout wrap justify-space-around pt-4>
          <v-flex xs12>
            <v-tabs
              color="transparent"
              touchless
              grow
              ref="tabs"
              class="nsbold"
              style="font-size: 16px"
              v-model="activeTab"
            >
              <!-- <v-tab> -->
              <v-tab :disabled="activeTab !== 0">
                <v-btn block color="#ff6200" dark>Create Room</v-btn></v-tab
              >
              <!-- <v-tab> -->
              <v-tab :disabled="activeTab !== 1">
                <v-btn block color="#ff6200" dark>Room Amenities</v-btn></v-tab
              >
              <!-- <v-tab> -->
              <v-tab :disabled="activeTab !== 2">
                <v-btn block color="#ff6200" dark>Room Rates</v-btn></v-tab
              >
              <!-- <v-tab> -->
              <v-tab :disabled="activeTab !== 2">
                <v-btn block color="#ff6200" dark>Food & Beverages</v-btn></v-tab
              >
              
            </v-tabs>
            <v-tabs-items v-model="activeTab" touchless>
              <v-tab-item :touch="false">
                <v-card pa-2>
                  <addRoom :FloorList="FloorList" :RoomCatList="RoomCatList" @id-received="nextTab" />
                </v-card>
              </v-tab-item>
              <v-tab-item :touch="false">
                <v-card pa-2>
                  <AddRoomAmenities :RoomAmenitiesList="RoomAmenitiesList" :hotelId="id" :roomId="roomId" @id-received="nextTab" />
                </v-card>
              </v-tab-item>
              <v-tab-item :touch="false">
                <v-card pa-2>
                  <AddRoomRate :roomData="roomData" @id-received="nextTab" />
                </v-card>
              </v-tab-item>
         
              <v-tab-item :touch="false">
                <v-card pa-2>
                  <AddRoomFood :roomData="roomData" @id-received="nextTab" />
                </v-card>
              </v-tab-item>
            </v-tabs-items>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import addRoom from "./AddRooms.vue";
import AddRoomAmenities from "./AddRoomAmenities.vue";
import AddRoomRate from "./AddRoomRate.vue";
import AddRoomFood from "./AddRoomFood.vue";
import axios from "axios";
export default {
  components: {
    addRoom,
    AddRoomAmenities,
    AddRoomRate,
    AddRoomFood,
  },
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      data: [],
      FloorList:[],
      RoomCatList:[],
      RoomAmenitiesList:[],
      msg: null,
      hotelId: null,
      roomId: null,
      page: 1,
      limit: 20,
      activeTab: 0,
      id: null,
      roomData:{},
      nowDate: new Date().toISOString().slice(0, 10),

      // tabs: ["Overview", "Rooms", "Location", "Details", "Amenities", "Policies"],
      form1Data: {
        name: "",
        contact: "",
      },
      form2Data: {
        name: "",
        contact: "",
      },
      savedTabs: [false, false, false, false, false, false],
    };
  },
  watch: {
  activeTab(newValue) {
    if (newValue === 2) {
      this.getroomData();
    }
  }
},

  mounted() {
 
 this.getFloors();
 this.getRoomCat();
 this.getRoomAmenities();

    this.disableSwipe();
    if (window.innerWidth <= 600) {
      const tabs = document.querySelector(".v-tabs");
      if (tabs) {
        tabs.addEventListener(
          "touchmove",
          (e) => {
            e.preventDefault();
          },
          { passive: false }
        );
      }
    }
  },
  methods: {
    disableSwipe() {
      const tabs = this.$refs.tabs;
      if (tabs) {
        tabs.addEventListener("touchstart", this.preventTouch, {
          passive: false,
        });
        tabs.addEventListener("touchmove", this.preventTouch, {
          passive: false,
        });
      }
    },
    preventTouch(event) {
      event.preventDefault();
    },
    handleIdReceived(data) {
      // this.id = id;
      // this.roomId = roomId;
      // this.nextTab();
      console.log("1")
      if (data.id) {        
      this.id = data.id;   
      console.log("2")

    }
    if (data.roomId) {
      this.roomId = data.roomId;
      console.log("3")

    }
    console.log("4")

    this.nextTab();
    },
    nextTab() {
      console.log("activetab=",this.activeTab)
      if (this.activeTab < 4) {
        this.activeTab += 1; // Move to the next tab
      }
    },
    getroomData() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/room/details",
        data: {
          roomId: localStorage.getItem("RoomId"),
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.roomData = response.data.data;
          // this.msg = response.data.msg;
          // this.showSnackBar = true;
        //  localStorage.removeItem("RoomId");
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    getFloors() {
        this.appLoading = true;
        axios
          .get("/hotel/get/floor", {
            headers: {
              token: localStorage.getItem("token"),
            },
            params: {
              hotelId: this.$route.query.id,
            },
          })
          .then((response) => {
            this.appLoading = false;
            this.FloorList = response.data.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getRoomCat() {
        this.appLoading = true;
        axios
          .get("/room/category/getlist", {
            headers: {
              token: localStorage.getItem("token"),
            },
            params: {
              hotelId: this.$route.query.id,
            },
          })
          .then((response) => {
            this.appLoading = false;
            this.RoomCatList = response.data.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
      getRoomAmenities() {
        this.appLoading = true;
        axios
          .post("/facilities/get/list", {
            headers: {
              token: localStorage.getItem("token"),
            },
            data: {
              hotelId: this.$route.query.id,
            },
          })
          .then((response) => {
            this.appLoading = false;
            this.RoomAmenitiesList = response.data.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },
  },
};
</script>
<style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}

@media (max-width: 600px) {
  .v-tabs {
    touch-action: none;
  }
}
</style>