<template>
  <div>
    <v-layout wrap justify-start pt-2>
      <v-flex xs12 sm3 align-self-center pl-8>
        <span
          class="title1"
          :style="{
            'font-size':
              $vuetify.breakpoint.name == 'xs'
                ? '20px'
                : $vuetify.breakpoint.name == 'sm'
                ? '20px'
                : $vuetify.breakpoint.name == 'md'
                ? '25px'
                : $vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          }"
          >Room Info</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-center pa-4>
      <v-flex xs12 sm6>
        <v-layout wrap justify-center>
          <v-flex xs12 sm10 text-left>
            <span class="title2">Display Name</span>
            <v-text-field
              dense
              outlined
              type="text"
              v-model="roomName"
              :rules="[rules.required]"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>

          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Room Category</span>
            <!-- <v-select
              hide-details="auto"
              v-model="roomType"
              :items="['Living Room', 'Bed Room']"
              :menu-props="{ maxHeight: '400' }"
              outlined
              persistent-hint
              dense
            ></v-select> -->
             <v-select
              hide-details="auto"
              v-model="roomType"
              :items="RoomCatList"
              :menu-props="{ maxHeight: '400' }"
            item-text="category_name"
              item-value="_id"
              outlined
              persistent-hint
              dense
            ></v-select>
          </v-flex>

          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Room View</span>
            <v-select
              hide-details="auto"
              v-model="roomView"
              :items="viewTypes"
              :menu-props="{ maxHeight: '400' }"
              outlined
              persistent-hint
              dense
            ></v-select>
          </v-flex>
          <v-flex xs12 sm10 pt-4 text-left>
        <v-radio-group
          dense
          v-model="viewType"
          row
          class="mt-0 pt-0"
          hide-details="auto"
        >
          <v-radio label="Full View" value="fullView"></v-radio>
          <v-radio label="Partial View" value="partialView"></v-radio>
        </v-radio-group>
      </v-flex>
          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Bed Type</span>
            <v-select
              hide-details="auto"
              v-model="bedType"
              :items="bedTypes"
              :menu-props="{ maxHeight: '400' }"
              outlined
              persistent-hint
              dense
            ></v-select>
          </v-flex>
          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Total Rooms</span>
            <v-text-field
              dense
              outlined
              type="number"
              hide-spin-buttons
              v-model="totalRooms"
              :rules="[rules.required]"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>

          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Room size(area)</span>
            <v-layout wrap justify-space-between>
              <v-flex xs3>
                <v-text-field
                  dense
                  v-model="width"
                  outlined
                  type="number"
                  hide-spin-buttons
                  hide-details="auto"
                />
              </v-flex>

              <v-flex xs1 align-self-center>
                <v-icon> mdi-close </v-icon>
              </v-flex>
              <v-flex xs3>
                <v-text-field
                  dense
                  v-model="length"
                  outlined
                  type="number"
                  hide-spin-buttons
                  hide-details="auto"
                />
              </v-flex>
              <v-flex xs4>
                <v-select
                  hide-details="auto"
                  v-model="areaType"
                  :items="['Square feet', 'Square meter']"
                  :menu-props="{ maxHeight: '400' }"
                  outlined
                  persistent-hint
                  dense
                ></v-select>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm10 pt-4 text-left>
  <span class="title2">Room No./Name</span>
  <v-layout wrap>
    <v-flex xs6>
      <v-text-field
        dense
        v-model="newRoomNumber"
        outlined
        type="text"
        hide-details="auto"
        placeholder="Room Number"
      />
    </v-flex>
    <v-flex xs4 pl-1>
      <v-select
        dense
        hide-details="auto"
        v-model="newFloor"
        :items="FloorList"
     
        :menu-props="{ maxHeight: '400' }"
        outlined
        placeholder="Select Floor"
      />
    </v-flex>
    <v-flex xs2 align-self-center text-center>
      <v-icon @click="addNewNo"  :disabled="!newRoomNumber ">>
        mdi-plus
      </v-icon>
    </v-flex>
  </v-layout>
  <v-layout wrap v-if="roomNumbers.length > 0">
    <v-flex
      xs12
      pt-4
      v-for="(room, index) in roomNumbers"
      :key="index"
    >
      <v-layout wrap>
        <v-flex xs6>
          <v-text-field
            dense
            hide-details
            v-model="room.roomNumber"
            outlined
            type="text"
            disabled
          />
        </v-flex>
        <v-flex xs4 pl-1>
          <v-text-field
            dense
            hide-details
            v-model="room.floorNumber"
            outlined
            type="text"
            disabled
          />
        </v-flex>
        <v-flex xs2 align-self-center text-center>
          <v-icon @click="deleteRoomNo(index)"> mdi-delete </v-icon>
        </v-flex>
      </v-layout>
    </v-flex>
  </v-layout>
</v-flex>

        </v-layout>
      </v-flex>
      <v-flex xs12 sm6>
        <v-layout wrap justify-center>
          <v-flex xs12 text-left>
            <span class="title2">Description</span>
            <v-textarea
              dense
              v-model="description"
              outlined
              type="text"
              hide-details="auto"
            >
            </v-textarea>
          </v-flex>
        </v-layout>
        <!-----------------------Room IMG--------------------------->
        <v-layout wrap pt-2>
          <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Room Images Upload</span>
          </v-flex>
          <v-flex xs3 pb-2 text-center>
                          <v-card
                            tile
                            elevation="0"
                            color="#e8e8e8"
                            class="mr-4"
                            height="80px"
                            width="90px"
                          >
                            <ImageComp
                              :singleImage="coverImage"
                              @stepper="winStepper"
                              :width="1251"
                              :height="400"
                              :componentType="'coverImage'"
                            />
                          </v-card>
                          <span class="title3" style="color: #505050"
                            >Cover Image</span
                          >
                        </v-flex>
          <v-flex xs12 sm3 v-for="(item, i) in imageArray2" :key="i">
            <v-img :src="item" height="80px" width="90px" contain>
              <v-layout wrap>
                <v-flex text-right pa-0>
                  <v-avatar color="#FF3434" size="15">
                    <v-icon color="#FFF" small @click="removeImageArray2(i)">
                      mdi-close
                    </v-icon>
                  </v-avatar>
                </v-flex>
              </v-layout>
            </v-img>
          </v-flex>
        </v-layout>
        <!-----------------------ADDITIONAL IMG--------------------------->
        <v-layout wrap pt-2>
          <v-flex xs12 sm3 pb-2 text-left>
            <v-card
              tile
              @click="$refs.files.click()"
              elevation="0"
              color="#ED7243"
              class="mr-4"
              height="80px"
              width="90px"
            >
              <input
                v-show="false"
                accept="image/*"
                id="file"
                ref="files"
                type="file"
                @change="uploadImages"
              />
              <v-layout wrap fill-height>
                <v-flex xs12 align-self-center text-center>
                  <v-icon color="white">mdi-plus-circle-outline</v-icon>
                </v-flex>
              </v-layout>
            </v-card>
            <span class="title3" style="color: #505050">Additional Images</span>
          </v-flex>
          <v-flex xs12 sm3 v-for="(item, i) in imageArray" :key="i">
            <v-img :src="item" height="80px" width="90px" contain>
              <v-layout wrap>
                <v-flex text-right pa-0>
                  <v-avatar color="#FF3434" size="15">
                    <v-icon color="#FFF" small @click="removeImageArray(i)">
                      mdi-close
                    </v-icon>
                  </v-avatar>
                </v-flex>
              </v-layout>
            </v-img>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm11 py-4 align-self-center>
        <v-divider></v-divider>
      </v-flex>
      <v-flex xs12 align-self-center>
        <v-layout wrap px-8>
          <v-flex xs12 text-left>
            <span
              class="title1"
              :style="{
                'font-size':
                  $vuetify.breakpoint.name == 'xs'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'sm'
                    ? '20px'
                    : $vuetify.breakpoint.name == 'md'
                    ? '25px'
                    : $vuetify.breakpoint.name == 'lg'
                    ? '25px'
                    : '30px',
              }"
              >Occupancy</span
            ></v-flex
          >
          <v-flex xs12 sm6 pa-2>
            <v-layout wrap justify-space-between align-center px-0 px-sm-2 px-md-0>
              <v-flex xs12 sm12 md7>
                <span class="title2a">Base Adults</span><br />
                <span class="subtitle2a"
                  >Ideal number of adults that can be accommodated in this
                  room</span
                >
              </v-flex>
              <v-flex xs12 sm10 md5>
                <v-layout align-center justify-end>
                  <v-btn
                    outlined
                    color="grey"
                    height="40px"
                    @click="decrement('minAdults')"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <v-text-field
                    v-model="occupency.minAdults"
                    outlined
                    hide-details
                    dense
                  />
                  <v-btn
                    outlined
                    color="grey"
                    height="40px"
                    @click="increment('minAdults')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm6 pa-2>
            <v-layout wrap justify-space-between align-center px-0 px-sm-2 px-md-0>
              <v-flex xs12 sm12 md7>
                <span class="title2a">Maximum Adults</span><br />
                <span class="subtitle2a"
                  >Maximum number of adults that can be accommodated in this
                  room</span
                >
              </v-flex>
              <v-flex xs12 sm10 md5>
                <v-layout align-center justify-end>
                  <v-btn
                    outlined
                    color="grey"
                    height="40px"
                    @click="decrement('maxAdults')"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <v-text-field
                    v-model="occupency.maxAdults"
                    outlined
                    hide-details
                    dense
                  />
                  <v-btn
                    outlined
                    color="grey"
                    height="40px"
                    @click="increment('maxAdults')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm6 pa-2>
            <v-layout wrap justify-space-between align-center px-0 px-sm-2 px-md-0>
              <v-flex xs12 sm12 md7>
                <span class="title2a">Number of Max Children</span><br />
                <span class="subtitle2a"
                  >Mention the maximum number of children who can stay in the
                  room</span
                >
              </v-flex>
              <v-flex xs12 sm10 md5>
                <v-layout align-center justify-end>
                  <v-btn
                    outlined
                    color="grey"
                    height="40px"
                    @click="decrement('maxChilds')"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <v-text-field
                    v-model="occupency.maxChilds"
                    outlined
                    hide-details
                    dense
                  />
                  <v-btn
                    outlined
                    color="grey"
                    height="40px"
                    @click="increment('maxChilds')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>

          <v-flex xs12 sm6 pa-2>
            <v-layout wrap justify-space-between align-center px-0 px-sm-2 px-md-0>
              <v-flex xs12 sm12 md7>
                <span class="title2a"
                  >Maximum Occupancy (adults & children)</span
                ><br />
                <span class="subtitle2a"
                  >Total number of adults & children that can be accommodated in
                  this room</span
                >
              </v-flex>
              <v-flex xs12 sm10 md5>
                <v-layout align-center justify-end>
                  <v-btn
                    outlined
                    color="grey"
                    height="40px"
                    @click="decrement('totalOccupency')"
                  >
                    <v-icon>mdi-minus</v-icon>
                  </v-btn>
                  <v-text-field
                    v-model="occupency.totalOccupency"
                    outlined
                    hide-details
                    dense
                  />
                  <v-btn
                    outlined
                    color="grey"
                    height="40px"
                    @click="increment('totalOccupency')"
                  >
                    <v-icon>mdi-plus</v-icon>
                  </v-btn>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 pa-2>
            <v-layout wrap justify-space-between align-center>
              <v-flex xs12>
                <span class="title2">Child Age</span>
            <v-select
              hide-details="auto"
               label="Paid child age"
              v-model="childAge"
              
              :items="['6-12 yrs', '6-18 yrs']"
              :menu-props="{ maxHeight: '400' }"
              outlined
              persistent-hint
              dense
            ></v-select>
              </v-flex>
          
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 pa-2>
            <v-layout wrap justify-space-between align-center>
              <v-flex xs12>
                <span class="title2">Infant Age</span>
            <v-select
              hide-details="auto"
              v-model="infantAge"
              label="No charge age"
               :items="['0-5 yrs', '0-6 yrs']"
              :menu-props="{ maxHeight: '400' }"
              outlined
              persistent-hint
              dense
            ></v-select>
              </v-flex>
          
            </v-layout>
          </v-flex>
       
          <v-flex xs12>
            <v-layout wrap justify-center justify-md-end>
              <v-flex xs12 sm10 md2 pt-4 text-right>
                <v-btn
                  @click="AddRoom()"
                  block
                  class="btnstly"
                  style="cursor: pointer"
                  ><span style="font-family: LexendFont; text-transform: none"
                    >Save</span
                  ></v-btn
                >
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
     
    </v-layout>
    <v-dialog
      v-model="cropImageDialog"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '80vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '50vw'
          : '40vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageCropper
              :image="currentImage"
              :key="currentImage"
              :width="700"
              :height="700"
              @stepper="imageCropper"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="cropImageDialog2"
      persistent
      :width="
        $vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'
          ? '100vw'
          : $vuetify.breakpoint.name == 'md'
          ? '80vw'
          : $vuetify.breakpoint.name == 'lg'
          ? '50vw'
          : '40vw'
      "
    >
      <v-card>
        <v-layout wrap justify-center pa-2>
          <v-flex xs12>
            <ImageCropper2
              :image="currentImage2"
              :key="currentImage2"
              :width="700"
              :height="700"
              @stepper="imageCropper2"
            />
          </v-flex>
        </v-layout>
      </v-card>
    </v-dialog>
  </div>
</template>
  <script>
import axios from "axios";
import ImageCropper from "./imageCropper";
import ImageComp from "./singleImages";
import ImageCropper2 from "./../../common/imageCropper2";
export default {
  props:["FloorList","RoomCatList"],
  components: {
    ImageComp,
    ImageCropper,
    ImageCropper2,
  },
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      data: [],
      msg: null,
      page: 1,
      limit: 20,
      nowDate: new Date().toISOString().slice(0, 10),
      newRoomNumber: '',
      newFloor: '',
      roomNumbers: [],
      roomName: "",
      description: "",
      roomType: "",
      roomView: "",
      bedType: "",
      totalRooms: "",
      viewType:"",
      infantAge:"",
      childAge:"",
      coverImage: "",
  
      // viewTypes: [
      //   "No View",
      //   "Sea View",
      //   "Valley View",
      //   "City View",
      //   "Mountain View",
      //   "Garden View",
      //   "Pool View",
      //   "River View",
      //   "Lake View",
      //   "Forest View",
      //   "Hill View",
      // ],
      viewTypes: [
    "No View",
    "Sea View",
    "Valley View",
    "Hill View",
    "Pool View",
    "Garden View",
    "River View",
    "Lake View",
    "Palace View",
    "Bay View",
    "Jungle View",
    "City View",
    "Landmark View",
    "Terrace View",
    "Courtyard View",
    "Desert View",
    "Golf Course View",
    "Mountain View",
    "Ocean View",
    "Backwater View",
    "Resort View",
    "Park View",
    "Lagoon View",
    "Forest View",
    "Beach View",
    "Airport View",
    "Countryside View",
    "Harbor View"
],
bedTypes: [
    "Twin Bed",
    "King Bed",
    "Queen Bed",
    "Double Bed",
    "Single Bed",
    "Sofa Bed",
    "Standard Bed",
    "1 King bed or 2 Twin Bed (s)",
    "1 Queen bed or 2 Twin Bed (s)",
    "1 Double bed or 2 Twin bed (s)",
    "Bunk Bed",
    "Futon",
    "Murphy",
    "Tatami Mats",
    "Orthopedic Mattress King (s)",
    "Orthopedic Mattress Queen (s)",
    "Orthopedic Standard Mattress"
],

      // bedTypes: [
      //   "Single Bed",
      //   "Double Bed",
      //   "Twin Bed",
      //   "Queen Size Bed",
      //   "King Size Bed",
      //   "Triple Bed",
      //   "Family Bed",
      //   "Extra Mattress",
      // ],
      width: "",
      length: "",
      areaType: "",
      occupency: {
        minAdults: 0,
        maxAdults: 0,
        maxChilds: 0,
        totalOccupency: 0,
      },
      imageArray2: [],
      currentImage2: "",
      cropImageDialog2: false,
      ImgselectedFiles2: "",
      roomImage: [],
      //
         // roomImage: "",
         boatLicenseProof: "",
      attachments: null,
      leaseProof: "",
      attachments2: null,
      formData: new FormData(),
      imageArray: [],
      imageFiles: [],
      selectedFiles: null,
      currentImage: "",
      cropImageDialog: false,
      imageId:"",
      roomId:"",
      //rules
      menu2: false,
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || "Invalid e-mail.";
        },
      },
      pinRules: [
        (v) => !!v || "PIN is required",
        (v) => (v && v.length === 6) || "PIN must be 6 digits",
      ],
      phoneRules: [
        (v) => !v || v.length <= 10 || "number must be less than 11 characters",
        (v) =>
          !v || v.length >= 10 || "number must be greater than 10 characters",
        (v) => !v || /^\d{10}$/.test(v) || "phone number must be a number",
      ],
    };
  },

  methods: {
    increment(field) {
      this.occupency[field]++;
    },
    decrement(field) {
      if (this.occupency[field] > 0) {
        this.occupency[field]--;
      }
    },
    addNewNo() {
      console.log("1")
      if(this.newFloor === null || this.newFloor === ''){
        this.newFloor=0;
      console.log("2")

      }
      if (this.newRoomNumber && this.newFloor!=null) {
      console.log("3")

        this.roomNumbers.push({
          roomNumber: this.newRoomNumber,
          floorNumber: this.newFloor,
        });
        this.newRoomNumber = '';
        this.newFloor = '';
      }
    },
    deleteRoomNo(index) {
      this.roomNumbers.splice(index, 1);
    },

    validation() {
      if (!this.category) {
        this.msg = "Please choose boat category";
        this.showSnackBar = true;
        return;
      } else if (!this.roomName) {
        this.msg = "Please enter property boat name";
        this.showSnackBar = true;
        return;
      } else if (!this.description) {
        this.msg = "Please enter total rooms";
        this.showSnackBar = true;
        return;
      } else if (!this.roomType) {
        this.msg = "Please choose type";
        this.showSnackBar = true;
        return;
      } else if (!this.roomView) {
        this.msg = "Please choose room view";
        this.showSnackBar = true;
        return;
      } else if (!this.otpMail) {
        this.msg = "Please enter OTP mail Id";
        this.showSnackBar = true;
        return;
      } else if (!this.expiryType) {
        this.msg = "Please choose expiry type";
        this.showSnackBar = true;
        return;
      } else if (!this.expiryDate) {
        this.msg = "Please enter expiry date";
        this.showSnackBar = true;
        return;
      } else if (!this.attachments) {
        this.msg = "Please upload boat licence proof";
        this.showSnackBar = true;
        return;
      } else if (!this.attachments2) {
        this.msg = "Please upload lease proof";
        this.showSnackBar = true;
        return;
      } else {
        this.addBoat();
      }
    },
    AddRoom() {
      this.appLoading = true;
      localStorage.removeItem("RoomId");
      axios({
        method: "POST",
        url: "/room/add",
        data: {
          name: this.roomName,
          description: this.description,
          roomCategory: this.roomType,
          roomView: this.roomView,
          bedView: this.bedType,
          totalRooms: this.totalRooms,
          viewType: this.viewType,
          infantAge: this.infantAge,
          childAge: this.childAge,
          roomWidth: this.width,
          roomLength: this.length,
          areaType: this.areaType,
          roomNumbers:this.roomNumbers,
          occupency:this.occupency,
          hotelId:this.$route.query.id,
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.imageId = response.data.data._id;
          this.roomId = response.data.data._id;
          localStorage.setItem("RoomId",this.roomId)
          console.log("id=", this.imageId);
          if (this.imageId) {
            console.log("sdfghj");
            this.ImageUpload(this.imageId);
          } else {
            console.log("img id not received");
            // this.$emit('id-received', this.hotel_id);
          }
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    getFloors() {
        this.appLoading = true;
        axios
          .get("/hotel/get/floor", {
            headers: {
              token: localStorage.getItem("token"),
            },
            params: {
              hotelId: this.route.query.id,
            },
          })
          .then((response) => {
            this.appLoading = false;
            this.FloorList = response.data.data;
          })
          .catch((err) => {
            this.appLoading = false;
            this.ServerError = true;
            console.log(err);
          });
      },

    openFileInput() {
      // Trigger a click event on the file input element
      this.$refs.fileInput.$el.querySelector("input").click();
    },
    openFileInput2() {
      // Trigger a click event on the file input element
      this.$refs.fileInput2.$el.querySelector("input").click();
    },
    winStepper(window_data) {
      if (window_data.type == "fullImage") {
        this.fullImage = window_data.selectedFiles;
        console.log("fullImage is", this.fullImage);
      }
      if (window_data.type == "coverImage") {
        this.coverImage = window_data.selectedFiles;
        console.log("coverImage is", this.coverImage);
      }
      if (window_data.type == "upperImage") {
        this.upperImage = window_data.selectedFiles;
        console.log("upperImage is", this.upperImage);
      }
      if (window_data.type == "interiorImage") {
        this.interiorImage = window_data.selectedFiles;
        console.log("interiorImage is", this.interiorImage);
      }
    },
    //rooms images
    uploadImages2(event) {
      if (this.imageArray2.length < 4) {
        this.currentImage2 = URL.createObjectURL(event.target.files[0]);
        this.cropImageDialog2 = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },

    removeImageArray2(i) {
      var values = this.formData.getAll("roomImage");
      values.splice(i, 1);

      // Remove the existing "roomImage" key from formData
      this.formData.delete("roomImage");

      // Re-append the modified array
      values.forEach((value, index) => {
        this.formData.append("roomImage", value, `roomImage${index + 1}`);
      });

      this.imageArray2.splice(i, 1);
    },
    imageCropper2(payload) {
      this.cropImageDialog2 = payload.dialog;
      if (payload.image) {
        this.collectImages2(payload.image);
      }
    },
    collectImages2(image) {
      this.ImgselectedFiles2 = image;
      // this.Images.append("image", this.ImgselectedFiles2);
      this.formData.append("roomImage", this.ImgselectedFiles2);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.imageArray2.push(urls);
        if (this.imageArray2) {
          this.roomImage = this.imageArray2[0];
        }
      };
      console.log("ImgselectedFiles==", this.ImgselectedFiles2);
    },
    //additional images
    uploadImages(event) {
      if (this.imageArray.length < 4) {
        this.currentImage = URL.createObjectURL(event.target.files[0]);
        this.cropImageDialog = true;
      } else {
        this.msg = "Maximum image limit exceeded!";
        this.showSnackBar = true;
        return;
      }
    },
    removeImageArray(i) {
      var values = this.formData.getAll("additionalImages");
      values.splice(i, 1);

      // Remove the existing "additionalImages" key from formData
      this.formData.delete("additionalImages");

      // Re-append the modified array
      values.forEach((value, index) => {
        this.formData.append("additionalImages", value, `image${index + 1}`);
      });

      this.imageArray.splice(i, 1);
    },

    imageCropper(payload) {
      this.cropImageDialog = payload.dialog;
      if (payload.image) {
        this.collectImages(payload.image);
      }
    },
    collectImages(image) {
      this.ImgselectedFiles = image;
      this.formData.append("additionalImages", this.ImgselectedFiles);
      var img = new Image();
      img.src = window.URL.createObjectURL(image);
      img.onload = () => {
        const urls = URL.createObjectURL(image);
        this.imageArray.push(urls);
        if (this.imageArray) {
          this.image = this.imageArray[0];
        }
      };
      console.log("ImgselectedFiles==", this.ImgselectedFiles);
    },
    getFiles() {
      var selectedFiles = this.boatLicenseProof;
      console.log("img=", this.boatLicenseProof);
      if (this.boatLicenseProof) {
        this.msg = null;
        this.attachments = selectedFiles;
        console.log("attachments=", this.attachments);
      }
    },
    getFiles2() {
      // this.categoryImg = this.Image
      var selectedFiles2 = this.leaseProof;
      console.log("img=", this.leaseProof);
      if (this.leaseProof) {
        this.msg = null;
        this.attachments2 = selectedFiles2;
        console.log("attachments2=", this.attachments2);
      }
    },
    ImageUpload(id) {
      
      this.formData.append("roomImage", this.coverImage);
      if (this.imageArray.length > 0) {
        for (var i = 0; i < this.imageFiles.length; i++)
          this.formData.append("additionalImages", this.imageFiles[i]);
      }
      this.formData.append("id", id);
      console.log("formData", this.formData);
      this.appLoading = true;
      axios({
        method: "post",
        url: "/room/upload/images",
        headers: {
          "Content-Type": "multipart/form-data",
          token: localStorage.getItem("token"),
        },
        data: this.formData,
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.formData = new FormData();
          this.roomImage = "";
          this.imageFiles = [];
          this.imageArray = [];
          this.msg = response.data.msg;
          this.showSnackBar = true;
          this.$emit('id-received', { hotelId: this.$route.query.id, roomId: this.roomId });
        } else {
          alert("failed to update cover image");
        }
      });
    },
  },
};
</script>
<style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}
</style>