<template>
  <div>
    <v-layout wrap justify-start pt-2>
      <v-flex xs12 sm6 align-self-center pl-8>
        <span
          class="title1"
          :style="{
            'font-size':
              $vuetify.breakpoint.name == 'xs'
                ? '20px'
                : $vuetify.breakpoint.name == 'sm'
                ? '20px'
                : $vuetify.breakpoint.name == 'md'
                ? '25px'
                : $vuetify.breakpoint.name == 'lg'
                ? '25px'
                : '30px',
          }"
          >Add Food & Beverages</span
        >
      </v-flex>
    </v-layout>
    <v-layout wrap justify-start pb-4 px-8>
      <v-flex xs12 sm10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm3 pt-4 px-2>
            <span class="title2">Is GST present?</span>

            <v-radio-group
              dense
              v-model="gstAvailable"
              row
              class="mt-0 pt-0"
              hide-details="auto"
            >
              <v-radio color="orange" label="Yes" :value="true"></v-radio>
              <v-radio color="orange" label="No" :value="false"></v-radio>
            </v-radio-group>
          </v-flex>
          <v-flex xs12 sm5 pt-4 px-2 text-left v-if="gstAvailable === true">
            <span class="title2">GST Number</span>
            <v-text-field
              dense
              v-model="gstNumber"
              outlined
              type="text"
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
          <!-- <v-flex xs12 sm10 pt-4 text-left>
            <span class="title2">Breakfast Items</span>
            <v-layout wrap>
              <v-flex xs6>
                <v-text-field
                  dense
                  v-model="newItemName"
                  outlined
                  type="text"
                  hide-details="auto"
                  placeholder="Item Name"
                />
              </v-flex>
              <v-flex xs4 pl-1>
                <v-text-field
                  dense
                  v-model="newRate"
                  outlined
                  type="number"
                  hide-details="auto"
                  placeholder="Rate"
                />
              </v-flex>
              <v-flex xs2 align-self-center text-center>
                <v-icon
                  @click="addBreakfastItem"
                  :disabled="!newItemName || !newRate"
                  >mdi-plus</v-icon
                >
              </v-flex>
            </v-layout>
            <v-layout wrap v-if="breakfastArray.length > 0">
              <v-flex
                xs12
                pt-4
                v-for="(item, index) in breakfastArray"
                :key="index"
              >
                <v-layout wrap>
                  <v-flex xs6>
                    <v-text-field
                      dense
                      hide-details
                      v-model="item.itemName"
                      outlined
                      type="text"
                      disabled
                    />
                  </v-flex>
                  <v-flex xs4 pl-1>
                    <v-text-field
                      dense
                      hide-details
                      v-model="item.rate"
                      outlined
                      type="number"
                      disabled
                    />
                  </v-flex>
                  <v-flex xs2 align-self-center text-center>
                    <v-icon @click="deleteBreakfastItem(index)"
                      >mdi-delete</v-icon
                    >
                  </v-flex>
                </v-layout>
              </v-flex>
            </v-layout>
          </v-flex> -->
        </v-layout>
      </v-flex>
      <v-flex xs12 sm10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm4 pt-4 px-2>
            <span class="title2">Cake</span>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-switch
              v-model="isCakeIncluded"
              label="Including"
              hide-details
              color="orange"
              class="mt-0"
            ></v-switch>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-text-field
              dense
              v-model="cakeRate"
              outlined
              type="number"
              hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm4 pt-4 px-2>
            <span class="title2">Fruit Basket</span>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-switch
              v-model="isFruitBasket"
              label="Including"
              hide-details
              color="orange"
              class="mt-0"
            ></v-switch>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-text-field
              dense
              v-model="fruitBasketRate"
              outlined
              type="number"
              hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm4 pt-4 px-2>
            <span class="title2">BBQ Grill</span>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-switch
              v-model="isBBQ"
              label="Including"
              hide-details
              color="orange"
              class="mt-0"
            ></v-switch>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-text-field
              dense
              v-model="bbqRate"
              outlined
              type="number"
              hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm4 pt-4 px-2>
            <span class="title2">Cook & Butler Service</span>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-switch
              v-model="isCook"
              label="Including"
              hide-details
              color="orange"
              class="mt-0"
            ></v-switch>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-text-field
              dense
              v-model="cookRate"
              outlined
              type="number"
              hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12 sm10>
        <v-layout wrap justify-start>
          <v-flex xs12 sm4 pt-4 px-2>
            <span class="title2">Honeymoon Inclusion</span>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-switch
              v-model="isHoneymoon"
              label="Including"
              hide-details
              color="orange"
              class="mt-0"
            ></v-switch>
          </v-flex>
          <v-flex xs12 sm4 pt-4 px-2>
            <v-text-field
              dense
              v-model="honeymoonRate"
              outlined
              type="number"
              hide-spin-buttons
              hide-details="auto"
            >
            </v-text-field>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-layout wrap justify-start px-2>
          <v-flex xs12 pt-4 >
            <span class="title2">Per Head Rate:</span>
          </v-flex>
          <v-flex xs12 sm6 md3 pr-0 pr-md-2 pt-4>
            <v-layout wrap justify-start>
              <v-flex xs6 align-self-center>
                <span class="title2">Breakfast</span>
              </v-flex>
              <v-flex xs4 align-self-center>
                <v-text-field
                  dense
                  v-model="breakfastRate"
                  outlined
                  type="number"
                  hide-spin-buttons
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 pt-4 px-2>
            <span class="title2">Menu:</span>
          </v-flex>
              <v-flex xs12 sm10 pt-4 align-self-center>
                <v-textarea
                  dense
                  v-model="breakfastMenu"
                  outlined
                  type="text"
                  hide-details="auto"
                >
                </v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 md2 pr-0 pr-md-2 pt-4>
            <v-layout wrap justify-start>
              <v-flex xs4 align-self-center>
                <span class="title2">Lunch</span>
              </v-flex>
              <v-flex xs6 align-self-center>
                <v-text-field
                  dense
                  v-model="lunchRate"
                  outlined
                  type="number"
                  hide-spin-buttons
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 pt-4 px-2>
            <span class="title2">Menu:</span>
          </v-flex>
              <v-flex xs12 sm10 pt-4 align-self-center>
                <v-textarea
                  dense
                  v-model="lunchMenu"
                  outlined
                  type="text"
                  hide-details="auto"
                >
                </v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 md4 pr-0 pr-md-2 pt-4>
            <v-layout wrap justify-start>
              <v-flex xs6 align-self-center>
                <span class="title2">Tea/Coffee-Snacks</span>
              </v-flex>
              <v-flex xs4 align-self-center>
                <v-text-field
                  dense
                  v-model="snacksRate"
                  outlined
                  type="number"
                  hide-spin-buttons
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 pt-4 px-2>
            <span class="title2">Menu:</span>
          </v-flex>
              <v-flex xs12 sm10 pt-4 align-self-center>
                <v-textarea
                  dense
                  v-model="snacksMenu"
                  outlined
                   type="text"
                  hide-details="auto"
                >
                </v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
          <v-flex xs12 sm6 md3  pt-4>
            <v-layout wrap justify-start>
              <v-flex xs4 align-self-center>
                <span class="title2">Dinner</span>
              </v-flex>
              <v-flex xs6 align-self-center>
                <v-text-field
                  dense
                  v-model="dinnerRate"
                  outlined
                  type="number"
                  hide-spin-buttons
                  hide-details="auto"
                >
                </v-text-field>
              </v-flex>
              <v-flex xs12 pt-4 px-2>
            <span class="title2">Menu:</span>
          </v-flex>
              <v-flex xs12 sm10 pt-4 align-self-center>
                <v-textarea
                  dense
                  v-model="dinnerMenu"
                  outlined
                  type="text"
                  hide-details="auto"
                >
                </v-textarea>
              </v-flex>
            </v-layout>
          </v-flex>
        </v-layout>
      </v-flex>
      <v-flex xs12>
        <v-layout wrap justify-end>
    <v-flex xs12 sm6 md3 pt-4 pr-0 pr-sm-2 pr-md-10>

 
  <v-btn
                  @click="AddRoomFood()"
                  block
                  class="btnstly"
                  style="cursor: pointer"
                  ><span style="font-family: LexendFont; text-transform: none"
                    >Save</span
                  ></v-btn
                >
              </v-flex>
            </v-layout>
      </v-flex>
    </v-layout>
  </div>
</template>
  <script>
import axios from "axios";
export default {
  props: ["roomData"],
  data() {
    return {
      showSnackBar: false,
      timeout: 2000,
      ServerError: false,
      appLoading: false,
      msg: null,
      page: 1,
      limit: 20,
      RoomId: localStorage.getItem("RoomId"),
      gstNumber: "",
      gstAvailable: true,
      isCakeIncluded: false,
      cakeRate: 0,
      isFruitBasket: false,
      fruitBasketRate: 0,
      isBBQ: false,
      bbqRate: 0,
      isCook: false,
      cookRate: 0,
      isHoneymoon: false,
      honeymoonRate: 0,
      breakfastRate: 0,
      lunchRate: 0,
      snacksRate: 0,
      dinnerRate: 0,
      breakfastMenu: "",
      lunchMenu: "",
      snacksMenu: "",
      dinnerMenu: "",

      // breakfastArray: [],
      // newItemName: "",
      // newRate: "",
      rules: {
        required: (value) => !!value || "Required.",
        counter: (value) => value.length <= 20 || "Max 20 characters",

        email: (value) => {
          const pattern =
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          return !value || pattern.test(value) || "Invalid e-mail.";
        },
      },
      pinRules: [
        (v) => !!v || "PIN is required",
        (v) => (v && v.length === 6) || "PIN must be 6 digits",
      ],
      phoneRules: [
        (v) => !v || v.length <= 10 || "number must be less than 11 characters",
        (v) =>
          !v || v.length >= 10 || "number must be greater than 10 characters",
        (v) => !v || /^\d{10}$/.test(v) || "phone number must be a number",
      ],
    };
  },
  //   mounted() {

  //  this.getData();
  //   },

  methods: {
    AddRoomFood() {
      this.appLoading = true;

      axios({
        method: "POST",
        url: "/room/add/foodandbeverage",
        data: {
          gstAvailable: this.gstAvailable,
          gstNumber: this.gstNumber,
          isCakeIncluded: this.isCakeIncluded,
          cakeRate: this.cakeRate,
          isFruitBasket: this.isFruitBasket,
          fruitBasketRate: this.fruitBasketRate,
          isBBQ: this.isBBQ,
          bbqRate: this.bbqRate,
          isCook: this.isCook,
          cookRate: this.cookRate,
          isHoneymoon: this.isHoneymoon,
          honeymoonRate: this.honeymoonRate,
          breakfastRate: this.breakfastRate,
          lunchRate: this.lunchRate,
          snacksRate: this.snacksRate,
          dinnerRate: this.dinnerRate,
          breakfastMenu: this.breakfastMenu,
          lunchMenu: this.lunchMenu,
          snacksMenu: this.snacksMenu,
          dinnerMenu: this.dinnerMenu,
          roomId: localStorage.getItem("RoomId"),
        },
        headers: {
          token: localStorage.getItem("token"),
        },
      }).then((response) => {
        this.appLoading = false;
        if (response.data.status == true) {
          this.msg = response.data.msg;
          this.showSnackBar = true;
           localStorage.removeItem("RoomId");
           this.$router.push("/hotel");
        } else {
          this.msg = response.data.msg;
          this.showSnackBar = true;
        }
      });
      // }
    },
    addBreakfastItem() {
      if (this.newItemName && this.newRate) {
        this.breakfastArray.push({
          itemName: this.newItemName,
          rate: this.newRate,
        });
        this.newItemName = "";
        this.newRate = "";
      }
    },
    deleteBreakfastItem(index) {
      this.breakfastArray.splice(index, 1);
    },
  },
};
</script>
<style scoped>
.btnstly {
  background: linear-gradient(
    to bottom,
    rgba(255, 133, 86, 1),
    rgba(247, 75, 9, 1),
    rgba(255, 70, 0, 1)
  );
  color: white;
}
</style>